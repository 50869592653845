import * as Types from 'views/common/redux/types';
import { PropMapper } from '@helpers/utilities';

const { properties } = PropMapper(
  Types,
  (type) => `GET_MASTER_${type?.toUpperCase()}_DATA`,
  (type) => `SET_MASTER_${type?.toUpperCase()}_DATA`,
  (type) => `POST_MASTER_${type?.toUpperCase()}_DATA`,
  (type) => `PUT_MASTER_${type?.toUpperCase()}_DATA`
);

const standard = properties('standard');
const department = properties('department');
const board = properties('board');
const medium = properties('medium');
const state = properties('state');
const district = properties('district');
const zipCode = properties('zipCode');
const exams = properties('exam');
const testType = properties('testType');
const subject = properties('subject');
const unit = properties('unit');
const topic = properties('topic');
const level = properties('level');
const testStatus = properties('testStatus');
const schoolType = properties('schoolType');
const examList = properties('examList');
const courseList = properties('courseList');
const clientList = properties('clientList');
const commonTestList = properties('commonTestList');
const subjectList = properties('subjectList');
const examRule = properties('examRule');
const personList = properties('personList');
const subjectDetail = properties('subjectDetail');
const questionCount = properties('questionCount');
const questionType = properties('questionType');
const questionPattern = properties('questionPattern');
const clpt = properties('clpt');

export {
  standard,
  department,
  board,
  medium,
  state,
  district,
  exams,
  zipCode,
  testType,
  subject,
  unit,
  topic,
  level,
  testStatus,
  schoolType,
  examList,
  courseList,
  clientList,
  commonTestList,
  subjectList,
  examRule,
  personList,
  subjectDetail,
  questionCount,
  questionType,
  questionPattern,
  clpt
};
