import { lazy } from 'react';

export const SettingsConfig = {
  routes: [
    {
      name: 'settings',
      path: '/settings',
      exact: true,
      component: lazy(() => import('./sections/settings'))
    },
    {
      path: '/settings/unitUpdate',
      exact: true,
      component: lazy(() => import('./sections/unitUpdate'))
    },
    {
      path: '/settings/topicUpdate',
      exact: true,
      component: lazy(() => import('./sections/topicUpdate'))
    }
  ]
};
