import * as Types from 'views/common/redux/types';
import { executeEpic } from '@helpers/utilities';
import {
  getStandardsApi,
  getDepartmentsApi,
  getBoardsApi,
  getMediumApi,
  getStateApi,
  getDistrictApi,
  getZipCodeApi,
  getExamsApi,
  getTestTypeApi,
  getSubjectApi,
  getUnitApi,
  getTopicApi,
  getLevelApi,
  getTestStatusApi,
  getSchoolTypeApi,
  getExamListApi,
  getCourseListApi,
  getClientListApi,
  getCommonTestListApi,
  getSubjectListApi,
  getExamRuleApi,
  getPersonApi,
  getSubjectDetailApi,
  getQuestionCountApi,
  getQuestionTypeApi,
  getQuestionPatternApi,
  getClptApi,
  putUnitApi,
  putTopicApi,
  postUnitApi,
  postTopicApi
} from 'views/common/api';
import {
  standard,
  department,
  board,
  medium,
  state,
  district,
  zipCode,
  exams,
  testType,
  subject,
  unit,
  topic,
  level,
  testStatus,
  schoolType,
  examList,
  courseList,
  clientList,
  commonTestList,
  subjectList,
  examRule,
  personList,
  subjectDetail,
  questionCount,
  questionType,
  questionPattern,
  clpt
} from 'views/common/redux/actions';
import { stopLoading, showAlert } from 'app/redux/actions';

const getStandardEpic = (action$) =>
  executeEpic(Types.GET_MASTER_STANDARD_DATA, getStandardsApi, (responseData) => [
    standard.set(responseData.response)
  ])(action$);

const getDepartEpic = (action$) =>
  executeEpic(Types.GET_MASTER_DEPARTMENT_DATA, getDepartmentsApi, (responseData) => [
    department.set(responseData.response)
  ])(action$);

const getBoardEpic = (action$) =>
  executeEpic(Types.GET_MASTER_BOARD_DATA, getBoardsApi, (responseData) => [
    board.set(responseData.response)
  ])(action$);

const getMediumEpic = (action$) =>
  executeEpic(Types.GET_MASTER_MEDIUM_DATA, getMediumApi, (responseData) => [
    medium.set(responseData.response)
  ])(action$);

const getStateEpic = (action$) =>
  executeEpic(Types.GET_MASTER_STATE_DATA, getStateApi, (responseData) => [
    state.set(responseData.response)
  ])(action$);

const getDistrictEpic = (action$) =>
  executeEpic(Types.GET_MASTER_DISTRICT_DATA, getDistrictApi, (responseData) => [
    district.set(responseData.response)
  ])(action$);

const getZipCodeEpic = (action$) =>
  executeEpic(Types.GET_MASTER_ZIPCODE_DATA, getZipCodeApi, (responseData) => [
    zipCode.set(responseData.response)
  ])(action$);

const getExamsEpic = (action$) =>
  executeEpic(Types.GET_MASTER_EXAM_DATA, getExamsApi, (responseData) => [
    exams.set(responseData.response)
  ])(action$);

const getTestTypeEpic = (action$) =>
  executeEpic(Types.GET_MASTER_TESTTYPE_DATA, getTestTypeApi, (responseData) => [
    testType.set(responseData.response)
  ])(action$);

const getSubjectEpic = (action$) =>
  executeEpic(Types.GET_MASTER_SUBJECT_DATA, getSubjectApi, (responseData, payload) => [
    subject.set({ response: responseData.response, filter: payload?.getByExam })
  ])(action$);

const getUnitEpic = (action$) =>
  executeEpic(Types.GET_MASTER_UNIT_DATA, getUnitApi, (responseData) => [
    unit.set(responseData.response)
  ])(action$);

const getTopicEpic = (action$) =>
  executeEpic(Types.GET_MASTER_TOPIC_DATA, getTopicApi, (responseData) => [
    topic.set(responseData.response)
  ])(action$);

const getLevelEpic = (action$) =>
  executeEpic(Types.GET_MASTER_LEVEL_DATA, getLevelApi, (responseData) => [
    level.set(responseData.response)
  ])(action$);
const getTestStatusEpic = (action$) =>
  executeEpic(Types.GET_MASTER_TESTSTATUS_DATA, getTestStatusApi, (responseData) => [
    testStatus.set(responseData.response)
  ])(action$);

const getSchoolTypeEpic = (action$) =>
  executeEpic(Types.GET_MASTER_SCHOOLTYPE_DATA, getSchoolTypeApi, (responseData) => [
    schoolType.set(responseData.response)
  ])(action$);

const getExamListEpic = (action$) =>
  executeEpic(Types.GET_MASTER_EXAMLIST_DATA, getExamListApi, (responseData) => [
    examList.set(responseData.response)
  ])(action$);

const getCourseListEpic = (action$) =>
  executeEpic(Types.GET_MASTER_COURSELIST_DATA, getCourseListApi, (responseData) => [
    courseList.set(responseData.response)
  ])(action$);

const getClientListEpic = (action$) =>
  executeEpic(Types.GET_MASTER_CLIENTLIST_DATA, getClientListApi, (responseData) => [
    clientList.set(responseData.response)
  ])(action$);

const getCommonTestListEpic = (action$) =>
  executeEpic(Types.GET_MASTER_COMMONTESTLIST_DATA, getCommonTestListApi, (responseData) => [
    commonTestList.set(responseData.response)
  ])(action$);

const getSubjectListEpic = (action$) =>
  executeEpic(Types.GET_MASTER_SUBJECTLIST_DATA, getSubjectListApi, (responseData) => [
    subjectList.set(responseData.response)
  ])(action$);

const examRuleEpic = (action$) =>
  executeEpic(Types.GET_MASTER_EXAMRULE_DATA, getExamRuleApi, (responseData) => [
    examRule.set(responseData.response)
  ])(action$);

const personEpic = (action$) =>
  executeEpic(Types.GET_MASTER_PERSONLIST_DATA, getPersonApi, (responseData) => [
    personList.set(responseData.response)
  ])(action$);

const subjectDetailEpic = (action$) =>
  executeEpic(Types.GET_MASTER_SUBJECTDETAIL_DATA, getSubjectDetailApi, (responseData) => [
    subjectDetail.set(responseData.response)
  ])(action$);

const questionCountEpic = (action$) =>
  executeEpic(Types.GET_MASTER_QUESTIONCOUNT_DATA, getQuestionCountApi, (responseData) => [
    questionCount.set(responseData.response)
  ])(action$);

const questionTypeEpic = (action$) =>
  executeEpic(Types.GET_MASTER_QUESTIONTYPE_DATA, getQuestionTypeApi, (responseData) => [
    questionType.set(responseData.response)
  ])(action$);

const questionPatternEpic = (action$) =>
  executeEpic(Types.GET_MASTER_QUESTIONPATTERN_DATA, getQuestionPatternApi, (responseData) => [
    questionPattern.set(responseData.response)
  ])(action$);

const getClptEpic = (action$) =>
  executeEpic(Types.GET_MASTER_CLPT_DATA, getClptApi, (responseData) => [
    clpt.set(responseData.response)
  ])(action$);

const postUnitEpic = (action$) =>
  executeEpic(Types.POST_MASTER_UNIT_DATA, postUnitApi, (responseData, payload) => [
    unit.get(payload?.unitQueryParam),
    stopLoading(),
    showAlert('Unit added successfully', 'success')
  ])(action$);

const updateUnitEpic = (action$) =>
  executeEpic(Types.PUT_MASTER_UNIT_DATA, putUnitApi, (responseData, payload) => [
    unit.get(payload?.unitQueryParam),
    stopLoading(),
    showAlert('Unit updated successfully', 'success')
  ])(action$);

const postTopicEpic = (action$) =>
  executeEpic(Types.POST_MASTER_TOPIC_DATA, postTopicApi, (responseData, payload) => [
    topic.get(payload?.topicQueryParam),
    stopLoading(),
    showAlert('Topic added successfully', 'success')
  ])(action$);

const updateTopicEpic = (action$) =>
  executeEpic(Types.PUT_MASTER_TOPIC_DATA, putTopicApi, (responseData, payload) => [
    topic.get(payload?.topicQueryParam),
    stopLoading(),
    showAlert('Topic updated successfully', 'success')
  ])(action$);

export default [
  getStandardEpic,
  getDepartEpic,
  getBoardEpic,
  getMediumEpic,
  getStateEpic,
  getDistrictEpic,
  getZipCodeEpic,
  getExamsEpic,
  getTestTypeEpic,
  getSubjectEpic,
  getUnitEpic,
  getTopicEpic,
  getLevelEpic,
  getTestStatusEpic,
  getSchoolTypeEpic,
  getExamListEpic,
  getCourseListEpic,
  getClientListEpic,
  getCommonTestListEpic,
  getSubjectListEpic,
  examRuleEpic,
  personEpic,
  subjectDetailEpic,
  questionCountEpic,
  questionTypeEpic,
  questionPatternEpic,
  getClptEpic,
  postUnitEpic,
  updateUnitEpic,
  postTopicEpic,
  updateTopicEpic
];
