import { memo } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
// import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
// import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  whiteColor,
  blackColor
} from 'assets/jss/components/styleUtil';
import { Stack } from '@mui/material';

const PREFIX = 'index';

const classes = {
  drawerPaper: `${PREFIX}-drawerPaper`,
  logo: `${PREFIX}-logo`,
  logoLink: `${PREFIX}-logoLink`,
  logoImage: `${PREFIX}-logoImage`,
  img: `${PREFIX}-img`,
  background: `${PREFIX}-background`,
  list: `${PREFIX}-list`,
  item: `${PREFIX}-item`,
  itemTextRTL: `${PREFIX}-itemTextRTL`,
  whiteFont: `${PREFIX}-whiteFont`,
  sidebarWrapper: `${PREFIX}-sidebarWrapper`,
  activePro: `${PREFIX}-activePro`,
  avatarDiv: `${PREFIX}-avatarDiv`,
  avatarSmall: `${PREFIX}-avatarSmall`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  active: `${PREFIX}-active`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      zIndex: '1',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },

  [`& .${classes.logo}`]: {
    position: 'relative',
    padding: '10px 10px',
    zIndex: '4'
  },

  [`& .${classes.logoLink}`]: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    '&,&:hover': {
      color: whiteColor
    }
  },

  [`& .${classes.logoImage}`]: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px'
  },

  [`& .${classes.img}`]: {
    width: '70px',
    top: '18px',
    left: '60px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0'
  },

  [`& .${classes.background}`]: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8'
    }
  },

  [`& .${classes.list}`]: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset'
  },

  [`& .${classes.item}`]: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none'
  },

  [`& .${classes.itemTextRTL}`]: {
    textAlign: 'right'
  },

  [`& .${classes.whiteFont}`]: {
    color: whiteColor
  },

  [`& .${classes.sidebarWrapper}`]: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: drawerWidth,
    zIndex: '4',
    overflowScrolling: 'touch'
  },

  [`& .${classes.activePro}`]: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px'
    }
  },

  [`& .${classes.avatarDiv}`]: {
    float: 'left'
  },

  [`& .${classes.avatarSmall}`]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: '10px'
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6.5)
    }
  },

  [`& .${classes.active}`]: {
    borderLeft: '4px solid blue'
  }
}));

const SidebarWrapper = styled('div')({
  position: 'relative',
  height: 'calc(100vh - 75px)',
  overflow: 'auto',
  width: drawerWidth,
  zIndex: '4',
  overflowScrolling: 'touch'
});

const itemLink = {
  width: 'auto',
  transition: 'all 300ms linear',
  // margin: "10px 15px 0",
  borderRadius: '3px',
  position: 'relative',
  display: 'block'
  // padding: "10px 15px",
};
const ItemIcon = styled('img')({
  height: '25px',
  fontSize: '14px',
  lineHeight: '30px',
  float: 'left',
  marginRight: '15px',
  textAlign: 'center',
  verticalAlign: 'middle'
});
const itemText = {
  ...defaultFont,
  margin: '0',
  lineHeight: '30px',
  fontSize: '14px'
};
const BrandImg = styled('img')({
  height: '40px',
  verticalAlign: 'middle',
  border: '0'
});

function CustomSideBar(props) {
  const { logo, routes, handleListItemClick, open, mobileOpen, image, handleDrawerToggle } = props;
  const container = window !== undefined ? () => window.document.body : undefined;
  const links = (
    <List
      sx={{
        marginTop: '20px',
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginBottom: '0',
        listStyle: 'none',
        position: 'unset'
      }}
    >
      {routes.map((prop, key) => (
        <Link
          to={prop.path}
          sx={{
            position: 'relative',
            display: 'block',
            textDecoration: 'none',
            '& .Mui-selected': { marginLeft: '4px solid blue' }
          }}
          activeClassName={classes.active}
          key={key}
          component={NavLink}
          onClick={handleListItemClick}
          color="inherit"
          underline="none"
        >
          <ListItemButton sx={{ ...itemLink }}>
            {typeof prop.icon === 'string' ? (
              <Tooltip title={prop.name} placement="right" arrow>
                {/* <Icon data-name={prop.name} className={clsx(classes.itemIcon)}>
                  {prop.icon}
                </Icon> */}
                <ItemIcon src={prop.icon} alt={prop.name} />
              </Tooltip>
            ) : (
              <Tooltip title={prop.name} placement="right" arrow>
                {/* <prop.icon data-name={prop.name} className={clsx(classes.itemIcon)} /> */}
                <ItemIcon src={prop.icon} alt={prop.name} />
              </Tooltip>
            )}
            <ListItemText primary={prop.name} sx={{ ...itemText }} disableTypography />
          </ListItemButton>
        </Link>
      ))}
    </List>
  );
  const brand = (
    <Stack direction="row">
      <Avatar sx={{ width: '50px', height: '40px', mr: '10px' }} src={image} />
      <BrandImg src={logo} alt="avatar" />
    </Stack>
  );
  return (
    <Root>
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <Toolbar sx={{ padding: '10px 16px 16px 0px' }}>{brand}</Toolbar>
          <Divider />
          <SidebarWrapper>{links}</SidebarWrapper>
          {/* {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
          ) : null} */}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <Toolbar style={{ padding: '10px 16px 16px 0px' }}>{brand}</Toolbar>
          <Divider />
          <SidebarWrapper>{links}</SidebarWrapper>
        </Drawer>
      </Hidden>
    </Root>
  );
}

export default memo(CustomSideBar);
