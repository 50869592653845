export const GET_MASTER_STANDARD_DATA = 'GET_MASTER_STANDARD_DATA';
export const SET_MASTER_STANDARD_DATA = 'SET_MASTER_STANDARD_DATA';

export const GET_MASTER_DEPARTMENT_DATA = 'GET_MASTER_DEPARTMENT_DATA';
export const SET_MASTER_DEPARTMENT_DATA = 'SET_MASTER_DEPARTMENT_DATA';

export const GET_MASTER_BOARD_DATA = 'GET_MASTER_BOARD_DATA';
export const SET_MASTER_BOARD_DATA = 'SET_MASTER_BOARD_DATA';

export const GET_MASTER_MEDIUM_DATA = 'GET_MASTER_MEDIUM_DATA';
export const SET_MASTER_MEDIUM_DATA = 'SET_MASTER_MEDIUM_DATA';

export const GET_MASTER_STATE_DATA = 'GET_MASTER_STATE_DATA';
export const SET_MASTER_STATE_DATA = 'SET_MASTER_STATE_DATA';

export const GET_MASTER_DISTRICT_DATA = 'GET_MASTER_DISTRICT_DATA';
export const SET_MASTER_DISTRICT_DATA = 'SET_MASTER_DISTRICT_DATA';

export const GET_MASTER_ZIPCODE_DATA = 'GET_MASTER_ZIPCODE_DATA';
export const SET_MASTER_ZIPCODE_DATA = 'SET_MASTER_ZIPCODE_DATA';

export const GET_MASTER_EXAM_DATA = 'GET_MASTER_EXAM_DATA';
export const SET_MASTER_EXAM_DATA = 'SET_MASTER_EXAM_DATA';

export const GET_MASTER_TESTTYPE_DATA = 'GET_MASTER_TESTTYPE_DATA';
export const SET_MASTER_TESTTYPE_DATA = 'SET_MASTER_TESTTYPE_DATA';

export const GET_MASTER_SUBJECT_DATA = 'GET_MASTER_SUBJECT_DATA';
export const SET_MASTER_SUBJECT_DATA = 'SET_MASTER_SUBJECT_DATA';

export const GET_MASTER_UNIT_DATA = 'GET_MASTER_UNIT_DATA';
export const SET_MASTER_UNIT_DATA = 'SET_MASTER_UNIT_DATA';
export const POST_MASTER_UNIT_DATA = 'POST_MASTER_UNIT_DATA';
export const PUT_MASTER_UNIT_DATA = 'PUT_MASTER_UNIT_DATA';

export const GET_MASTER_TOPIC_DATA = 'GET_MASTER_TOPIC_DATA';
export const SET_MASTER_TOPIC_DATA = 'SET_MASTER_TOPIC_DATA';
export const POST_MASTER_TOPIC_DATA = 'POST_MASTER_TOPIC_DATA';
export const PUT_MASTER_TOPIC_DATA = 'PUT_MASTER_TOPIC_DATA';

export const GET_MASTER_LEVEL_DATA = 'GET_MASTER_LEVEL_DATA';
export const SET_MASTER_LEVEL_DATA = 'SET_MASTER_LEVEL_DATA';

export const GET_MASTER_TESTSTATUS_DATA = 'GET_MASTER_TESTSTATUS_DATA';
export const SET_MASTER_TESTSTATUS_DATA = 'SET_MASTER_TESTSTATUS_DATA';

export const GET_MASTER_SCHOOLTYPE_DATA = 'GET_MASTER_SCHOOLTYPE_DATA';
export const SET_MASTER_SCHOOLTYPE_DATA = 'SET_MASTER_SCHOOLTYPE_DATA';

export const GET_MASTER_EXAMLIST_DATA = 'GET_MASTER_EXAMLIST_DATA';
export const SET_MASTER_EXAMLIST_DATA = 'SET_MASTER_EXAMLIST_DATA';

export const GET_MASTER_COURSELIST_DATA = 'GET_MASTER_COURSELIST_DATA';
export const SET_MASTER_COURSELIST_DATA = 'SET_MASTER_COURSELIST_DATA';

export const GET_MASTER_CLIENTLIST_DATA = 'GET_MASTER_CLIENTLIST_DATA';
export const SET_MASTER_CLIENTLIST_DATA = 'SET_MASTER_CLIENTLIST_DATA';

export const GET_MASTER_COMMONTESTLIST_DATA = 'GET_MASTER_COMMONTESTLIST_DATA';
export const SET_MASTER_COMMONTESTLIST_DATA = 'SET_MASTER_COMMONTESTLIST_DATA';

export const GET_MASTER_SUBJECTLIST_DATA = 'GET_MASTER_SUBJECTLIST_DATA';
export const SET_MASTER_SUBJECTLIST_DATA = 'SET_MASTER_SUBJECTLIST_DATA';

export const GET_MASTER_EXAMRULE_DATA = 'GET_MASTER_EXAMRULE_DATA';
export const SET_MASTER_EXAMRULE_DATA = 'SET_MASTER_EXAMRULE_DATA';

export const GET_MASTER_PERSONLIST_DATA = 'GET_MASTER_PERSONLIST_DATA';
export const SET_MASTER_PERSONLIST_DATA = 'SET_MASTER_PERSONLIST_DATA';

export const GET_MASTER_SUBJECTDETAIL_DATA = 'GET_MASTER_SUBJECTDETAIL_DATA';
export const SET_MASTER_SUBJECTDETAIL_DATA = 'SET_MASTER_SUBJECTDETAIL_DATA';

export const GET_MASTER_QUESTIONCOUNT_DATA = 'GET_MASTER_QUESTIONCOUNT_DATA';
export const SET_MASTER_QUESTIONCOUNT_DATA = 'SET_MASTER_QUESTIONCOUNT_DATA';

export const GET_MASTER_QUESTIONTYPE_DATA = 'GET_MASTER_QUESTIONTYPE_DATA';
export const SET_MASTER_QUESTIONTYPE_DATA = 'SET_MASTER_QUESTIONTYPE_DATA';

export const GET_MASTER_QUESTIONPATTERN_DATA = 'GET_MASTER_QUESTIONPATTERN_DATA';
export const SET_MASTER_QUESTIONPATTERN_DATA = 'SET_MASTER_QUESTIONPATTERN_DATA';

export const GET_MASTER_CLPT_DATA = 'GET_MASTER_CLPT_DATA';
export const SET_MASTER_CLPT_DATA = 'SET_MASTER_CLPT_DATA';
